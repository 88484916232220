@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-latest-postscarousel {
    .alpha {
        margin-bottom: 1.5em;
    }
    .leftcol {
        h3 + a {
            color: $atlasred;
            margin-top: 2.25rem;
        }
    }
    .carousel-wrapper {
        margin-top: 2em;
        overflow: hidden;
        position: relative;
        .greyfade {
            background-image: linear-gradient(to right,rgba($grey, 0%),$grey,);
            width: 300px;
            height: 100%;
            top: 0;
            right: 0;
            position: absolute;
            z-index: 10;
        }
    }
    .posts-carousel {
        //margin-left: -215px;
        .slick-track {
            transition: all 340ms ease-in-out;
        }
        .slick-slide {
            margin: 0 10px;
            .slide-item {
                background-color: $atlasred;
                width: 520px !important;
                //width: 196px !important;
                transition: all 350ms ease-in-out;
                overflow: hidden;
                &.no-thumbnail {
                    background-image: url('../../assets/images/element_circles.svg') !important;
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-position: bottom -15% left 60% ;
                }
            }
            &:hover {
                .slide-item {
                    .inner {
                        opacity: 1;
                        .overlay {
                            mix-blend-mode: normal;
                            opacity: 0.8;
                        }
                    }
                }
            }
            &.slick-current {
                .slide-item {
                    //width: 628px !important;
                    .inner {
                        opacity: 1;
                    }
                }
                &:hover {
                    .slide-item {
                        .inner {
                            .overlay {
                                mix-blend-mode: multiply;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .inner {
            min-height: 334px;
            max-height: 334px;
            padding: 5%;
            display: flex;
            align-items: center;
            position: relative;
            opacity: 0;
            transition: opacity 350ms linear;
            .overlay {
                will-change: opacity;
                mix-blend-mode: multiply;
                background-color: $atlasred;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
            .inner-copy {
                color: $white;
                max-width: 420px;
                position: relative;
                z-index: 10;
                .post-date {
                    margin-bottom: 1em;
                    font-size: 12px;
                    display: block;
                }
                .post-title {
                    margin-bottom: 1.5em;
                    font-size: 20px;
                    font-variation-settings: "wght" 700;
                }
                .nexbtn {
                    &:after {
                        background-color: #FFFFFF61;
                    }
                }
            }
        }
    }
    .slick-nav {
        border-top: none;
        margin: 2em 0 0;
        padding-top: 0;
        text-align: left;
        .slick-btn {
            background-image: url('../../assets/images/icon_link_arrow.svg');
            background-size: cover;
            width: 35px;
            height: 35px;
            &:hover {
                background-color: rgba($white, 45%);
            }
        }
    }
    @include bpr-media-breakpoint(down, xxl) {
        .posts-carousel {
            margin-left: 0;
        }
	}
    @include bpr-media-breakpoint(down, md) {
        .leftcol {
            text-align: center;
        }
        .carousel-wrapper {
            .greyfade {
                display: none;
            }
        }
        .posts-carousel {
            .slick-slide {
                margin: 0 7.5px;
                .slide-item {
                    width: 100% !important;
                }
                &.slick-current {
                    .slide-item {
                        width: 100% !important;
                    }
                }
            }
        }
        .slick-nav {
            margin: 2em auto 0;
            text-align: center;
        }
	}
}